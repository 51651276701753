import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { EventManagerFactory } from '../../ethereum/typechain/EventManagerFactory';
import './PreviewTicket.css';
type State = {
  
};



export default function PreviewTicket() {

    const [count, setCount] = useState({count: 0});
    const [state, setstate] = useState({title: 'Loading...', desc:'Loading...', location: 'Loading...', startTime:[''], seatsPerType: [0], pricePerType: [0], minPrice: 0, maxPrice: 0,image:'/img/loader.gif',country:'',category:''});
    const [tickets, setTickets] = useState({buyTickets: ''});
    const [first, setFirst] = useState <number[]> ([]);
    const [second, setSecond] = useState <number[]> ([]);
    const [bookedSeats, setBookedSeats] = useState<number[]> ([]);
    const [hash, setHash] = useState("");
    const [done, setDone] = useState(false);

    // @ts-ignore
    const { address } = useParams();
    
    const eventInstance = EventManagerFactory.connect(
        address,
        window.wallet ?? window.provider
    );

    const fetchData = async () => {
        const title = await eventInstance.eventName();
        const desc = await eventInstance.eventDesc();
        const location = await eventInstance.eventLocation();
        const startTime1 = await (await eventInstance.eventStartTime()).toNumber();
        const startTime2 = ((new Date(startTime1*1000)).toString()).split("GMT+0530 (India Standard Time)");
        const startTime = startTime2[0].split(' ');
        const seatsPer = await eventInstance.getSeats();
        const seatsPerType = seatsPer.map(s => s.toNumber());
        const pricePer = await eventInstance.getPrices();
        const pricePer1 = pricePer.map(s => ethers.utils.formatEther(s));
        const pricePerType = pricePer1.map(s => +s);

        const minPrice = Math.min(...pricePerType);
        const maxPrice = Math.max(...pricePerType);

        const seatCnt = await eventInstance.getSeatsCount();
        var seatCnt1 = seatCnt.map(s => s.toNumber());

        var seatCnt2: number[] = [];
        seatCnt2.push(1);
        
        for(var i=1; i<seatCnt1.length; i++)
        {
            seatCnt1[i] += seatCnt1[i-1];
        }
        for(var i=0; i<seatCnt1.length-1; i++)
        {
            seatCnt2.push(seatCnt1[i] + 1);
        }

        const seats = await (await eventInstance.totalSeats()).toNumber();
        var booked: number[] = [];
        for(var i=1; i<=seats; i++)
        {
            var addr = await eventInstance.seatOwner(i);
            if(addr !== "0x0000000000000000000000000000000000000000")
            {
                booked.push(i);
            }
        }
        
        setFirst(seatCnt2);
        setSecond(seatCnt1);
        setBookedSeats(booked);

        const descArray = title?.replace(/\[|\]/g, "").split(',')
        // TITLE ======
        const titl = descArray[0]?.replace(/['"]+/g, '')
        // IMAGE =====
        const image = descArray[3]?.replace(/['"]+/g, '')
        // COUNTRY ====
        const country = descArray[2]?.replace(/['"]+/g, '')
        // CATEGORY ====
        const category = descArray[1]?.replace(/['"]+/g, '').split('#')
        
        //@ts-ignore
        setstate({title:titl, desc, location, startTime, seatsPerType, pricePerType, minPrice, maxPrice,image:image,country:country,category:category});

        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }
    }

    useEffect(()=>{(async () =>
        {
          await fetchData();
        }
      )()
    },[])

    const handleChange = (e: { target: { value: any; }; }) => {
        setTickets({buyTickets: e.target.value});
        // console.log(tickets.buyTickets);
    }

    const handleTickets = async () => {
        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }

        try {
            const bookTickets = tickets.buyTickets.split(',').map(s => +s);
            console.log(bookTickets);

            var price = 0;
            for(var i=0; i<bookTickets.length; i++)
            {
                var type = await (await eventInstance.seatTypeId(bookTickets[i])).toNumber();
                price += state.pricePerType[type];
            }

            // console.log( (String(price)) );
            const tx = await eventInstance.connect(window.wallet).buyTicket(bookTickets, {value: ethers.utils.parseEther(String(price))});
            tx.wait();
            console.log(tx);
            setHash(tx.hash);
            setDone(true);
            alert("You paid "+String(price)+" ES. Tickets have been booked.");
        } catch (error) {
            // @ts-ignore
            alert(error.message);
            console.log(error.message);
        }
        
    }

    return (
        <div>
            <Header />
            <br/>
            <div className="wrapper-container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className='category-event-box'>
                            <img className='event-Img-three' src={state.image ? state.image : '/img/noPreview.png'} />

                            <div className='listing-text-ev'>
                                <div className="list-box-flex">
                                    <p className="location-txt event-name">{state.title}</p>
                                </div>                                
                                <div className="list-box-flex d-flex justify-content-between">
                                    <p className="location-txt">{state.country}</p>
                                    <p className="location-txt">ES {state.minPrice} - ES {state.maxPrice}</p>
                                </div>
                            </div>
                               
                        </div>
                    </div>
                </div>

                <div className="air-space-box alert alert-info mt-3" role="alert">
                    All Event details will disappear once Event <strong>“Start date &amp; time of event”</strong> begins. Save details
                    (Screenshots, etc) of event booked to avoid inconvenience. Booking DApp is a decentralized
                    application and doesn’t support any mediation between buyer &amp; seller of events. Please do your due
                    diligence by contacting event creator before booking tickets.
                </div>

                <div className="air-space-box">
                    <div className="event-time-box">
                        <div>
                            <p>{state.startTime[1]} {state.startTime[2]} {state.startTime[3]}, {state.startTime[0]}</p>
                            <p>Starts at {state.startTime[4]}</p>
                        </div>
                        <div>
                            {/*<select className="select-style" name="select" id="select">
                                <option value="volvo">Normal Ticket ES:250</option>
                                <option value="saab">ES 677</option>
                                <option value="mercedes">ES 200</option>
                                <option value="audi">ES </option>
                            </select>*/}
                            {
                                state.pricePerType.map((ele, i) => (
                                    <p><span className="hex-txt">&#x2022;</span> Type {i+1} ticket (Seat numbers: {first[i]} - {second[i]}) : ES {ele} ({state.seatsPerType[i]} seats left) </p>
                                ))
                            }
                        </div>
                        <div>
                            <div className="scroll-count">
                               
                                    {/* <button className="inc" onClick={(e) => this.increment(e)}>+</button> */}
                                    {/* <div>{this.state.count}</div> */}
                                    {/* <button className="dec" onClick={(e) => this.decrement(e)}>-</button> */}
                            </div>
                        </div>
                    </div>

                    <label> Seat Numbers already booked: {bookedSeats.join(',  ')} </label>
                    <br/><br/>

                    <label> Seat Numbers</label>
                    {/*<p className="blck-txt">Block C (ROW B to C)</p>
                    <p >CC5, CC6</p>

                    <label className='control-label'>Number of seats of each type</label>*/}
                    <input
                        className="form-control form-control-lg"
                        type="text"
                        name="buyTickets"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={tickets.buyTickets}
                        required
                    />
                    <p className='field-note-txt'>Enter the seat numbers you want to book/buy.(** Insert comma separated values, and do not use space after comma)</p> 

                    <div className='d-flex mt-3' >
                       <button className="form-submit-btn-0 Started-btn-btn mt-0" onClick={handleTickets}> Buy Tickets</button> 
                    
                       <p className='text-secondary ml-2' >
                          On Click of this button your selected Tickets Payment will be deducted as per the
                          Listed per Ticket Rate
                       </p>
                    </div>

                    <br/><br/>

                    <div>
                        {
                            done ? 
                                <>
                                    <div className="event-time-box">
                                        <br />
                                        <i className="fa fa-check-circle" style={{ fontSize: '8em', color: '#54BE3D' }}></i>
                                        {/* <p>
                                            {' '}
                                            Transaction Sussessful{' '}
                                        </p> */}
                                        <br /> <br /> <br />
                                        <table className="table">
                            
                                        <tr>
                                            <td> Transaction Hash : </td>
                                            <td>
                                            <a href={'https://eraswap.info/txn/'+hash}> {hash} </a>{' '}
                                            </td>
                                        </tr>
                            
                                        <tr>
                                            <td>Timestamp : </td>
                                            <td>
                                                <p>{Date.now()}</p>
                                            </td>
                                        </tr>
                            
                                        <hr />
                                        </table>
                                    </div>
                                    <p><strong>** Please note this transaction hash, will be required for validation.</strong></p>
                                </>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
    }