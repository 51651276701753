import { useEffect, useState } from 'react';

function Event() {
    const [hash] = useState(window.location.hash)
    const [event,setEvent] = useState({
       events : [] 
    })

    const getEvents = async () => {
        const filter = window.bookingdappInstance.filters.NewEvent(null, null, null, null, null, null);
        const logs = await window.bookingdappInstance.queryFilter(filter);
        const eventAll = logs.map(ele => ele.args);
        console.log("All :",eventAll)
        
        setEvent({events:[...event.events,...eventAll]})
        
        return eventAll;
    }

    useEffect(() => {
       getEvents() 
    },[])

    const allEvent = event.events

    const categoryData = allEvent.filter((ele) => {
        const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
        // CATEGORY ======
        const category = descArray[1]?.replace(/['"]+/g, '')
        
        return category == hash && Date.now() < ((Number(ele[5])) * 1000)
    })

    return categoryData;
}

export { Event };

