import queryString from 'query-string';
import { useEffect, useState } from 'react';

function Events() {
    const [event,setEvent] = useState({
       events : [] 
    })

    const parsed = queryString.parse(window.location.search);

    console.log(parsed)

    const getEvents = async () => {
        const filter = window.bookingdappInstance.filters.NewEvent(null, null, null, null, null, null);
        const logs = await window.bookingdappInstance.queryFilter(filter);
        const eventAll = logs.map(ele => ele.args);
        console.log("All :",eventAll)
        
        setEvent({events:[...event.events,...eventAll]})
        
        return eventAll;
    }

    useEffect(() => {
       getEvents() 
    },[])

    const allEvent = event.events

    const categoryData = allEvent.filter((ele) => {
        const dateStr = 'Thu Oct 28 2021 15:00:00'

        const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
        // WHAT ======
        const what = descArray[1]?.replace(/['"]+/g, '')
        // WHERE ======
        const where = descArray[2]?.replace(/['"]+/g, '')
        // WHEN ======
        var when = String(new Date((Number(ele[5])) * 1000))

        // DATE FROM SEARCH
        const date = new Date(parsed.when)

        console.log(when)
        console.log('when'+' '+date)

        return what == parsed.what || parsed.where == where || when == date 
    })

    return categoryData
}

export { Events };

