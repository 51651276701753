import { ethers } from 'ethers';
import { CustomProvider, addresses } from 'eraswap-sdk';
// import { solidityKeccak256 } from 'ethers/lib/utils';

// import { BookingDappManagerFactory } from 'eraswap-sdk/dist/typechain/ESN';
import { BookingDappManagerFactory } from './typechain/BookingDappManagerFactory';

// const config = addresses['production'];
// const config = addresses[process.env.REACT_APP_ENV === 'production' ? 'production' : 'development'];

window.provider = new CustomProvider(
    // process.env.REACT_APP_ENV === 'production' ? 'mainnet' : 'testnet'
    'mainnet'
);

window.bookingdappInstance = BookingDappManagerFactory.connect('0x4392C99c2d11fde2EE05958bc9fa118311ab01fa', window.provider);
/*testnet with no es-sdk: '0x23d5d09f3B1FD065Ec371A8Acd4c259D061Bf94D'*/

if(process.env.REACT_APP_NODE_ENV === 'development')
{
    window.wallet = new ethers.Wallet("0x671f38c40868b5d92c481e0f82ccf3c1a597f5a971d6f89ed7bd16c24c7d5e37", window.provider); // 0x646cC3cbe2cdDB26bbdEE94579479FE062c3FD00
    // window.wallet = new ethers.Wallet("0xe67156a04862a7c0c93e77d1d4ebd2a7a7b16ec4971ed57248cbba68d5d01106", window.provider); // 0x732397c90ADBADE7D725A122de8c298476A6a878
}
