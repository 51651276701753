import React, { Component } from 'react';
import './Footer.css';


type Props = {};

type State = {
  
};

export class Footer extends Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {

        };

    }


    render() {
        return (
            <div className='Footer-container'>
              
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3">
                            <img className='rentDaap-Img' src="/img/booklogo.png" alt='rent' />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4">
                            <div className='rentDaap-help'>
                                <ul>
                                    <h5 className='footer-link'>HELPFUL LINKS</h5>
                                    <li><a href="https://bookingdapp.com/Myaccount" target='_blank' >My Account</a></li>
                                    <li><a target='_blank' href="">Ticket Your Event</a></li>
                                    <li><a target='_blank' href="https://t.me/eraswap">Get In Touch</a></li>
                                    <li><a target='_blank' href="https://eraswaptoken.io/pdf/eraswap_whitepaper.pdf">Whitepaper</a></li>
                                    <li><a target='_blank' href="https://www.coingecko.com/en/coins/era-swap-token">CoinGecko</a></li>
                                </ul>
                                <ul>
                                    <h5 className='other-footer-txt'>EXPLORE</h5>
                                    <li><a href="">Concerts</a></li>
                                    <li><a href="/pdf/Renting Dapp Terms & Service.pdf">Conferences</a></li>
                                    <li><a href='/pdf/Renting Dapp Privacy.pdf'
                                        target='_blank' >Family</a></li>
                                    <li><a href="">Musical</a></li>
                                    <li><a href="">Music Festival</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-5">
                            <div className='subscribe-container'>
                                <div className='subscribe-detail'>NEWS LETTER</div>
                                <div className="flex-box-subs">
                                    <form>
                                        <input
                                            placeholder='Your email address'
                                            className='subscribe-field'
                                        />
                                    </form>

                                    <button className='subscribe-rent-btn m-0'>
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}




export default Footer;
