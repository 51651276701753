import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MyListing.css';
// import { Col, Button, Container, Row } from 'react-bootstrap';/

type Props = {};

type State = {
  
};

export class MyListing extends Component<Props, State>{

    constructor(props:Props) {
      super(props);
      this.state = {
        allEvents: [],
      };
    }

    getEvents = async () => {

        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }
        const filter = window.bookingdappInstance.filters.NewEvent(null, window.wallet.address, null, null, null, null);
        const logs = await window.bookingdappInstance.queryFilter(filter);
        const eventAll = logs.map(ele => ele.args);
        console.log("All :",eventAll)
        console.log("filter :",filter)
        console.log("logs :",logs)
        this.setState({...this.state , allEvents : eventAll})
        return eventAll;
    }

    async componentDidMount() {
        this.getEvents();
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="alert alert-info" role="alert">
                        All Event Tickets Booked will disappear at “Start date &amp; time of event”. Save
                        details (Screenshots, etc) of Tickets booked before event begins to avoid inconvenience. Booking
                        DApp is a decentralized application and doesn’t provide any mediation between buyer &amp; seller of
                        events. Please provide maximum information, contact number, email &amp; website address etc while
                        filling the form so that buyers are confident to communicate with you.
                        After Preview of your listed event, if you realize form is not properly filled, then you may choose to
                        cancel your event &amp; create new event.
                    </div>
                    
                    <h3>Live Events</h3>
                    <div className="row">
                        {
                            //@ts-ignore
                            this.state.allEvents.map((ele: any) => {
                                const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                                // TITLE ======
                                const title = descArray[0]?.replace(/['"]+/g, '')
                                // IMAGE =====
                                const image = descArray[3]?.replace(/['"]+/g, '')
                                    
                                
                                return ( Date.now()<((Number(ele[5])) * 1000) )
                                    ?   <div className="col-xs-12 mb-4 col-sm-6 col-md-6">
                                            <div className='category-three-box'>
                                                <div className="small-ev-box">
                                                    <p className="ev-month"> {String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0].split(' ')[1]} </p>
                                                    <p className="ev-date"> {String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0].split(' ')[2]} </p>
                                                </div>
                                                <img className='listing-Img-three' src={image ? image : './img/noPreview.png'} />
                                                
                                                <div className='listing-text'>
                                                    <div className="list-box-flex">
                                                        <p className="location-txt">
                                                           <a className='event-name' > {title} </a>
                                                        </p>
                                                    </div>
                                                    <div className="list-box-flex">
                                                        <p className="location-txt mb-1">{ele[4]}</p>
                                                        <p className='location-txt view-details' style={{fontSize:'14px',color:"rgb(227, 135, 37)"}} > View details of this event in the <strong>HOME </strong> page</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    :   null
                            })
                        }
                    </div>

                    <hr/>

                    <h3>Expired Events</h3>
                    <div className="row">
                        {
                            //@ts-ignore
                            this.state.allEvents.map((ele: any) => {
                                const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                                // TITLE ======
                                const title = descArray[0]?.replace(/['"]+/g, '')
                                // IMAGE =====
                                const image = descArray[3]?.replace(/['"]+/g, '')


                                return ( Date.now()>=((Number(ele[5])) * 1000) )
                                    ?   <div className="col-xs-12 mb-4 col-sm-6 col-md-6">
                                            <div className='category-three-box'>
                                                <div className="small-ev-box">
                                                    <p className="ev-month"> {String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0].split(' ')[1]} </p>
                                                    <p className="ev-date"> {String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0].split(' ')[2]} </p>
                                                </div>
                                                <img className='listing-Img-three' src={image ? image : './img/noPreview.png'} />
                                                <div className='listing-text'>
                                                    <div className="list-box-flex">
                                                        <p className="location-txt">
                                                            <a className='event-name' >{title}</a>
                                                        </p>
                                                    </div>
                                                    <div className="list-box-flex">
                                                        <p className="location-txt">{ele[4]}</p>
                                                        <p className='location-txt view-details' style={{fontSize:'14px',color:"rgb(227, 135, 37)"}} > View details of this event in the <strong>HOME </strong> page</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :   null
                            })
                        }
                    </div>
                </div>
            </div>

        );

    }
}


export default MyListing;