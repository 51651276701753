import React, { Component } from 'react';
import './Kycform.css';
// import { Col, Button, Container, Row } from 'react-bootstrap';

type Props = {};

type State = {
  
};

export class Kycform extends Component<Props, State>{


  render() {
    return (
      <div>
        <div className="container">
          <div className='d-flex flex-column' >

            <a href='https://kycdapp.com/' target='_blank' ><button className='btn border mb-2 ' >Apply KYC on KYC DApp</button></a>
            <a href='https://kycdapp.com/form/2' target='_blank' ><button className='btn border ' >Apply for KYC LEVEL 2</button></a>

          </div>
        </div>
      </div>
    );

  }
}


export default Kycform;