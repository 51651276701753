import React, { Component } from 'react';
import './Myaccount.css'
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';


type Props = {};

type State = {
  
};

export class Myaccount extends Component<Props, State>{
    constructor(props:Props) {
      super(props);
      this.state = {
  
      };
    }


    render() {
        return (
            <div>
                <Header />
                <Sidebar/>
            </div>
        );

    }
}


export default Myaccount;