import ethers from 'ethers';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
// import { Col, Button, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import { StateList } from '../../components/StateList/StateList';
import './CreateEvent.css';

type Props = {};

type State = {};

export class CreateEvent extends Component<Props, State> {

    constructor(props:Props) {
      super(props);
      this.state = {
          types: 0,
          byte: 'Loading...',
          hash: 'Loading...',
          done: false,
          image: 'https://www.penworthy.com/Image/Getimage?id=C:\Repositories\Common\About%20Us\Slide1.jpg',
      };
    }

    renderTimeStamp(datetime : string): number {
        const date = new Date(datetime);
        const timeStamp = date.getTime();
        return (timeStamp/1000);
    }

    TxnDetails( hash: {} | null | undefined, byte: {} | null | undefined ) {
        return (
          <>
          <div className='position-relative' >
            <div className='success-header' >
               <i className="fa fa-check-circle success-icon" ></i>
               <div className='text-center' >
                 <span className='success-color d-block' >Transaction Successful</span> 
                 <span>Your Event has been created</span> 
               </div>
            </div>

            <div className='success-animation' ></div>
                
              <div className="table">
                <div className='table-tr' >
                    <strong > Transaction Hash : </strong>
                    <div className='alert alert-success' ><a href={'https://eraswap.info/txn/'+hash}>{hash} </a></div>
                </div>

                <div className='table-tr' > 
                    <strong > Your Event Address : </strong > 
                    <div className='alert alert-success' ><p>{byte}</p></div>
                </div>
              </div>
                    
            </div>
          </>
        );
      };
   

    render() {
        return (    
            <div>
                <Header />
                <div className="RentPage-wrapper-container mt100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-10 col-md-8 col-lg-7 mx-auto mt40 mb40">
                                <Card >
                                    <Card.Body>
                                        <div className='table-catg-details'>
                                            <div className='r-col-d-12'>
                                                {/*<h5 className="mt20">Category</h5>
                                                <p className='category-select-txt'>{new URLSearchParams(this.props.location.search).get("category")} - {new URLSearchParams(this.props.location.search).get("sub")}</p>*/}

                                                <h5>Please fill in details of your event</h5>
                                                <Formik
                                                    initialValues={{ title: ['','','','','','','',''], desc:'' , address: '', start: '', types: 0, seats: '', prices: '', incentive: 0 }}
                                                    
                                                    onSubmit={async (values, { setSubmitting }) => { 
                                                        try {
                                                            alert(JSON.stringify(values, null, 2));
 
                                                            // const product = await window.rentingDappInstance.connect(window.wallet).addItem(values.title, values.address, ethers.utils.parseEther(String(values.maxrent)), ethers.utils.parseEther(String(values.security)), ethers.utils.parseEther(String(values.cancellation)), values.description, ethers.utils.formatBytes32String(categoryId), listDate);
                                                            if(window.wallet === undefined)
                                                            {
                                                                alert("Wallet not loaded");
                                                                return;
                                                            }

                                                            const event = await window.bookingdappInstance.connect(window.wallet).createEvent(
                                                                JSON.stringify(values.title), 
                                                                values.desc,
                                                                values.address, 
                                                                this.renderTimeStamp(values.start), 
                                                                values.types, 
                                                                values.seats.split(',').map(s => +s), 
                                                                values.prices.split(',').map(s => ethers.utils.parseEther(s)),
                                                                values.incentive,
                                                                {gasLimit: 8000000}
                                                            );

                                                            const receipt = await event.wait();
                                                            const logs = window.bookingdappInstance.interface.parseLog(receipt.logs[0]);
                                                            // console.log(logs.args[1]);
                                                            // console.log("TXN Hash: ", event.hash);
                                                            this.setState({ byte:logs.args[1] });
                                                            this.setState({ hash: event.hash });
                                                            this.setState({ done: true });
                                                                
                                                            setSubmitting(false);   
                                                            alert("Event successfully listed on BookingDApp!");
                                                        }  catch (error) {
                                                            alert(error.message);
                                                            console.log(error.message);
                                                        }                                                  
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                        setFieldValue
                                                    }) => (
                                                            <form onSubmit={handleSubmit} >
                                                                <div>
                                                                    <label className='control-label'>Event Title</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="text"
                                                                        name="title[0]"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title[0]}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Mention your event name.</p>  
                                                                    <br/>

                                                                    <label className='control-label'>Enter Your/Organizer name *</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="text"
                                                                        name="title[4]"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title[4]}
                                                                        required
                                                                    />
                                                                    <br/>

                                                                    <label className='control-label'>Enter Your/Organizer Contact number *</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="number"
                                                                        name="title[5]"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title[5]}
                                                                        required
                                                                    />
                                                                    <br/>

                                                                    <label className='control-label'>Enter Your/Organizer Email address *</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="email"
                                                                        name="title[6]"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title[6]}
                                                                        required
                                                                    />
                                                                    <br/>

                                                                    <label className='control-label'>Paste Your/Organizer website URL </label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="text"
                                                                        name="title[7]"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title[7]}
                                                                    />
                                                                    <br/>
                                                                
                                                                    <label className='control-label'>Event Category (Type of Event) *</label>
                                
                                                                    <select name='title[1]' onChange={handleChange} className="form-control form-control-md" aria-label="Default select example" required>
                                                                        <option selected>Event Category </option>
                                                                        <option value="#business">Business/Promotion/Social Events</option>
                                                                        <option value="#concert">Concerts/Show</option>
                                                                        <option value="#fest">Fest</option>
                                                                        <option value="#seminars">Conferences/Seminars</option>
                                                                        <option value="#party">Party</option>
                                                                        <option value="#trip">Trip</option>
                                                                        <option value="#speech">Speech</option>
                                                                        <option value="#other">Other</option>
                                                                    </select>

                                                                    <br/>
                                                                    <label className='control-label'>Event Place *</label>
                                                                    <select name='title[2]' onChange={handleChange} className="form-control form-control-md" aria-label="Default select example" required>
                                                                        <StateList/>
                                                                    </select>

                                                                    <br/>
                                                                    <label className='control-label'>Upload Images of your Event Or Upload EraSwap.Cloud Link</label>

                                                                    {/* FILE UPLOADING ==== */}
                                                                    <div className="upload-img">
                                                                      <div className="left">
                                                                        <img id="img-uploaded" src={values.title[3].length > 1 ? values.title[3] : 'https://cibistore.com/wp-content/plugins/wp-ultimo/assets/img/no-preview.png'} alt="No preview image" />
                                                                      </div>
                                                                
                                                                      <div className="right">
                                                                          <input type="text" name='title[3]' className="img-path" placeholder="paste era-swap cloud link OR any img link" onChange={(e) => setFieldValue("title[3]",e.target.value)} />
                                                                      </div>
                                                                    </div>
                                                                
                                                                    <br/>
                                                                    <label className='control-label'>Event Description</label>
                                                                    <textarea
                                                                        className="form-control form-control-lg"
                                                                        placeholder=''
                                                                        name="desc"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.desc}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>For Style use{' '}<a href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf">MarkDown</a> or embed code </p>   
                                                                    
                                                                    
                                                                    <br/>
                                                                    <label className='control-label'>Start date & time of event</label>
                                                                    <input 
                                                                        className="form-control" 
                                                                        type="datetime-local" 
                                                                        name="start" 
                                                                        onChange={handleChange} 
                                                                        onBlur={handleBlur}
                                                                        value ={values.start}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Mention your event start date & time.</p> 

                                                                    <br/>
                                                                    <label className='control-label'>Types of seats</label>
                                                                    <input 
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="types"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.types}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Mention the total number of seating categories.</p>

                                                                    <br/>
                                                                    <label className='control-label'>Number of seats of each type</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="text"
                                                                        name="seats"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.seats}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Enter the number of seats of each type. The values should be comma seperated and in order.(** Do not use space after comma.)</p> 

                                                                    <br/>
                                                                    <label className='control-label'>Price of seat of each type</label>
                                                                    <input
                                                                        className="form-control form-control-md"
                                                                        type="text"
                                                                        name="prices"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.prices}
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Enter the price of seat of each type. The values should be comma seperated and in order.(** Do not use space after comma.)</p> 

                                                                    <br/>
                                                                    <label className='control-label'>Incentive* <strong>(%)</strong></label>
                                                                    <div className="input-group col-md-6 pl-0">
                                                                        <input 
                                                                            className="form-control "
                                                                            type="number"
                                                                            name="incentive"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.incentive}
                                                                            max={15}
                                                                            min={0}
                                                                        />
                                                                    </div>
                                                                    <p className='field-note-txt'>Cannot be more than <strong>15%</strong></p>
                
                                                                    <div className='location-flex mt-3'>
                                                                        <div className="location-txt"> 
                                                                            <h5>Specify Event Location</h5>    
                                                                        </div>
                                                                    </div>
                                                                    <textarea
                                                                        className='form-control'
                                                                        placeholder=''
                                                                        name='address'
                                                                        value={values.address}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        rows={3}
                                                                        data-error='Write your message'
                                                                        required
                                                                    />
                                                                    <p className='field-note-txt'>Enter event location address.</p>
                                                                    <br/>

                                                                    <button className='submit-form-btn' type="submit" disabled={isSubmitting}>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </form>   
                                                        )}
                                                </Formik>

                                            </div>
                                        </div>

                                        <div>
                                            {
                                                //@ts-ignore
                                                this.state.done ? this.TxnDetails( this.state.hash,this.state.byte ) : null
                                            }
                                        </div>

                                    </Card.Body>
                                </Card>
                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


export default CreateEvent;