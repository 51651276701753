import React, { Component } from 'react';
import {
    BrowserRouter as Router, Link, Route
} from 'react-router-dom';
import Header from '../../components/Header/Header';
import TicketList from '../../components/TicketList/TicketList';
import UsedTicket from '../../components/UsedTickets/UsedTickets';
import './Myticket.css';

const routes = [
    {
        path: '/mytickets',
        exact: true,
        sidebar: () => <div>
          <TicketList/>
        </div>,
        main: () => <h2></h2>
    },
    {
        path: '/usedticket',
        sidebar: () => <div> <UsedTicket/></div>,
        main: () => <h2></h2>
    },
]

type Props = {};

type State = {
  
};

export class Myticket extends Component<Props, State>{
    constructor(props:Props) {
      super(props);
      this.state = {
  
      };
    }




    render() {
        return (
            <div>
                <Header />
                <div className="ticket-bgd-img">
                    <h4 className="tick-heading">My Tickets</h4>
                </div>
                <div className="container">
                    <Router>
                        <div className="two-tick-option">
                            <Link to="/mytickets"><p className="tick-txt-opt">My Tickets</p></Link>
                            <Link to="/usedticket"><p className="tick-txt-opt">Expired Tickets</p></Link>
                        </div>

                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                component={route.sidebar}
                            />
                        ))}

                        <div className="alert mt-3 alert-info" role="alert">
                            Here is your ticket 
                            To check other details of Event please go to upcoming Events at home page &gt;  Event Details.
                            All Event details will disappear once Event <strong>“Start date &amp; time of event”</strong> begins. Save details (Screenshots,etc) of event booked to avoid inconvenience. Booking DApp is a decentralized application and doesn’t support any mediation between buyer & seller of events. Please do your due diligence by contacting event creator before booking tickets.
                        </div>

                    </Router>
                </div>
            </div>
        );
    }
}

export default Myticket;