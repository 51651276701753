import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import PreviewTicket from './components/PreviewTicket/PreviewTicket';
import CreateEvent from './containers/CreateEvent/CreateEvent';
import EventDetail from './containers/EventDetail/EventDetail';
import EventPage from './containers/EventPage/EventPage';
import Homepage from './containers/Homepage/Homepage';
import Myaccount from './containers/Myaccount/Myaccount';
import Myticket from './containers/Myticket/Myticket';
import { SearchResult } from './containers/SearchResult';
import { ThemeProvider } from './lib/Theme';
import AllEvent from './containers/AllEvent/AllEvent'
 
function App() {
  return (
    <div className="App">
    <ThemeProvider>
        <Router>
          <Switch>
          <Route exact path="/" component={Homepage} />
           <Route exact path="/Myaccount" component={Myaccount} />
           <Route exact path="/Myticket" component={Myticket} />
           <Route exact path="/EventPage" component={EventPage} />
           <Route exact path="/CreateEvent" component={CreateEvent} />
           <Route exact path="/searchResult" component={SearchResult} />
           <Route exact path="/allevent" component={AllEvent} />
           <Route exact path="/EventDetail/:address" component={EventDetail} />
           <Route exact path="/PreviewTicket/:address" component={PreviewTicket} />
           </Switch>
        </Router>

        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
