import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import './EventPage.css';
import { Event } from './partials/Event';

export function EventPage() {
    const [hash] = useState(window.location.hash)
    const categoryData = Event()

    console.log(categoryData)

    return (
        <div>
            <Header />
            <div className="container my-3">
                <h3 className='mb-0' style={{textTransform:'uppercase'}} >
                   {hash == '#concert' && "Concerts" ||
                    hash == '#fest' && "Fest" ||
                    hash == '#concert' && "Concerts" ||
                    hash == '#seminer' && "Seminer" ||
                    hash == '#business' && "Seminars" ||
                    hash == '#food' && "food festival" ||
                    hash == '#speech' && "Speech" ||
                    hash == '#other' && "Other" ||
                    hash == '#party' && "Party"}
                </h3>
                <p className='mb-3' >{ categoryData.length } Events available</p>

                <div className="row">
                    {/* {categoryData.map(({img}) => <div className="col-sm-4"> <EventItem img={img} /> </div>)} */}
                    {
                      categoryData.length > 0 ? categoryData.map((ele: any) => {
                        const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                        // TITLE ======
                        const title = descArray[0]?.replace(/['"]+/g, '')
                        // IMAGE =====
                        const image = descArray[3]?.replace(/['"]+/g, '')
                        // DATE ======
                        var fullDate = String(new Date((Number(ele[5])) * 1000))
                        var index = fullDate.lastIndexOf(':') +3

                        const date = fullDate.substring(0, index)
    
                        return ( Date.now()<((Number(ele[5])) * 1000) )
                        ? <div className="col-md-4 mb-3">
                              <div className='category-three-box'>
                                  <img className='box-Img-three' src={image ? image : './img/noPreview.png'} />
                                  
                                  <div className='rentCategory-text'>
                                      <div className="list-box-flex">
                                          <Link className='event-name text-clamp-1' to={'/EventDetail/'+ele[2]}>{title}</Link>
                                      </div>
                                      
                                      <div className="list-box-flex">
                                           <p className="location-txt mb-1"><strong>Location:</strong> {ele[4]}</p>
                                              <p className="location-txt">
                                                {
                                                  ( Date.now()<((Number(ele[5])) * 1000) )
                                                    ? <p><strong>Starting at:</strong> {date}</p>
                                                      : 'Ended'
                                                }
                                              </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        : null
                          
                      })
                            :
                      <>
                        <div className='no-available' >         
                           <h3>No event available</h3>
                        </div>
                      </>
                    }
                </div>
            </div>
        </div>
    );
};


export default EventPage;