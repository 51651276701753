import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './TicketList.css';

type Props = {};

type State = {
  
};



export class TicketList extends Component<Props, State>{

    constructor(props:Props) {
      super(props);
      this.state = {
        allTickets: [],
        allStatus: [],
      };
    }

    getTickets = async () => {
        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }

        const filter = window.bookingdappInstance.filters.BoughtTickets(null, window.wallet.address, null, null, null, null);
        const logs_book = await window.bookingdappInstance.queryFilter(filter);

        const filter1 = window.bookingdappInstance.filters.CancelTickets(null, window.wallet.address, null, null, null, null);
        const logs_cancel = await window.bookingdappInstance.queryFilter(filter1);

        const combined_logs = [...logs_book, ...logs_cancel].sort((l1, l2) => l1.blockNumber > l2.blockNumber ? 1 : -1);
        // console.log(combined_logs);

        const ticketAll = combined_logs.map(ele => ele.args);
        this.setState({...this.state , allTickets : ticketAll});

        var status: number[] = [];
        combined_logs.forEach(log => {
            if(log.event === 'BoughtTickets')
                status.push(1);
            else
                status.push(0);
        })
        this.setState({...this.state, allStatus : status});

        console.log("All tickets:",ticketAll);
    }

    async componentDidMount() {
        this.getTickets();
    }

    render() {
        return (
            <div>
                <div className="wrapper-container">
                    <div className="row">

                        {
                            //@ts-ignore
                            this.state.allTickets.map((ele: any, i: number) => {
                                const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                                // TITLE ======
                                const title = descArray[0]?.replace(/['"]+/g, '')
                                // CATEGORY ====
                                const category = descArray[1]?.replace(/['"]+/g, '').split('#')
                                // COUNTRY ====
                                const country = descArray[2]?.replace(/['"]+/g, '')

                                
                                return (Date.now() < ((Number(ele[5])) * 1000))
                                        ?<div className="col-xs-12 col-sm-6 col-md-6">
                                            <div className="tickt-detail">
                                                <div>
                                                    <a href='https://www.unitag.io/qrcode'><img className="qr-img-scan" src='https://www.unitag.io/qreator/generate?crs=Ppv8rOENN3V1lAwTz82zPpEYgrTpeQPpAxSJGcmyf1yS40m%252F8TYex%252BClEuWu4lenvXZtoPs%252F%252BUrLXgu0YhszNgP%252BKdjyjPlmstXQT%252FaVrtPKhQyftmIdGYv13ikDwALrMbZP22mR79KHkzbFuKXEpiL8j20cuH2aGWOj2IjvLpcUzuo31AnGGGBeZdrGyuu6Mb1zDGpyywrS%252B5yeqbhCDi5N2jmuM%252FTAPgpUpoL%252FcocZ0EYmXmQgDKIdeF33i3Py4HjYkfLywjMpK%252F3qQWgtrBl1c23E1wTz0lBeRnnwr2kurpUSyPrULt%252B7TKfjjaFxmoonjuL9UxJGy2SCaPT%252FuA%253D%253D&crd=fhOysE0g3Bah%252BuqXA7NPQ6%252BvVUBl2O2Fufhv4lJCRtSccnJc3vL0A0h7RJ9tipEJadEbc05VSbS3mAIhPIe7TQ%253D%253D' alt='QR Code'/></a>
                                                    <br/>
                                                    <Link className="color-name float-right" to={'/EventDetail/'+ele[0]}>{title}</Link>
                                                </div>
                                                <div className="container-mytick">
                                                    <p className="no-tick">
                                                        {ele[2].length} 
                                                        {
                                                            (ele[2].length > 1)
                                                            ? " Tickets"
                                                            : " Ticket"
                                                        }
                                                    </p>

                                                    <p>
                                                        <b>Seat Numbers:  </b>
                                                        {ele[2].join(', ')}
                                                    </p>
                                                    <p>
                                                        <b>
                                                        {
                                                            //@ts-ignore
                                                            (this.state.allStatus[i] === 1)
                                                            ? "Bought"
                                                            : "Cancelled" 
                                                        }
                                                        </b>
                                                    </p>
                                                
                                                    <div className="date-ticket">
                                                    <p>
                                                        <b>Starting at: </b>
                                                        { 
                                                            String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0]
                                                        }
                                                    </p>
                                                    </div>

                                                    <p><b>Venue: </b>{ele[4]}</p>
                                                    
                                                    <p><b>Category : </b>{category}</p>
                                                    
                                                    <p className='mb-2' ><b>Country : </b>{country}</p>
                                                </div>
                                            </div>
                                        </div>
                                        :null
                            })
                        }

                    </div>

                    <br/><br/><br/>
                </div>
            </div>

        );

    }
}

export default TicketList;