import React,{ useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';

function AllEvent() {
    const [event,setEvent] = useState({
       events : [] 
    })

    const getEvents = async () => {
        const filter = window.bookingdappInstance.filters.NewEvent(null, null, null, null, null, null);
        const logs = await window.bookingdappInstance.queryFilter(filter);
        const eventAll = logs.map(ele => ele.args);
        console.log("All :",eventAll)
        
        setEvent({events:[...event.events,...eventAll]})
        
        return eventAll;
    }

    useEffect(() => {
       getEvents() 
    },[])

    const allEvent = event.events

    return (
      <>
        <Header/>
        <br/>
        <div className="container my-3">
            <p className="subtitle">Discover the fun!</p>
            <div className="upcoming-display mb-4">
               <h3 className="font-weight-bold">Upcoming Events</h3>
            </div>

            <div className='row'>
                               
              {
                allEvent.map((ele) => {
                   const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                   // TITLE ======
                   const title = descArray[0]?.replace(/['"]+/g, '')
                   // IMAGE =====
                   const image = descArray[3]?.replace(/['"]+/g, '')


                   return ( Date.now()<((Number(ele[5])) * 1000) )
                       ? <div className="col-md-4 mb-4">
                           <div className='category-three-box'>
                               <img className='box-Img-three' src={image ? image : './img/noPreview.png'} />
                                                                    
                           <div className='rentCategory-text'>
                               <div className="list-box-flex">
                                    <Link className='event-name text-clamp-1' to={'/EventDetail/'+ele[2]}>{title}</Link>
                               </div>
                                                                       
                               <div className="list-box-flex">
                                  <p className="location-txt mb-1"><strong>Location:</strong> {ele[4]}</p>
                                  <p className="location-txt">
                                    {
                                      ( Date.now()<((Number(ele[5])) * 1000) )
                                         ? <p><strong>Starting at:</strong> {String(new Date((Number(ele[5])) * 1000)).split("GMT+0530 (India Standard Time)")[0]}</p>
                                            : 'Ended'
                                    }
                                   </p>
                               </div>
                             </div>
                           </div>
                         </div>
                            : null
                })
              }

            </div>
        </div>
      </>  
    );
}

export default AllEvent
