import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

type Props = {};

type State = {
  
};

export class Navbar extends Component<Props, State>{
  public state: any;

  constructor(props:Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  
  toggle = () => {
    //@ts-ignore
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div>
        <nav className="mb-1 navbar navbar-expand-lg navbar-dark info-color">

          <a className="navbar-brand" href="/" > <img className='bookDaap-Img'  src="/img/whitelogo.png" /></a>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarSupportedContent-4">

            <ul className="navbar-nav ml-auto">

              <li className="nav-item active">
                <Link className="nav-link" to="/#">
                  Home
                  <span className="sr-only">(current)</span>
                </Link>
              </li>

              <li className="nav-item active">
                <Link className="nav-link" to="/Myticket">
                  My Tickets
                  <span className="sr-only">(current)</span>
                </Link>
              </li>

              <li className="nav-item active position-relative">
                <Link className="nav-link" to="#">
                  <p onClick={this.toggle} aria-hidden="true">Chat</p>
                </Link>

                <div className={`chat-dropdown ${!this.state.isOpen ? 'd-none' : ''}`} >
                    <div className="alert alert-warning" role="alert" >You can chat with your peers (Lesser/ Lessee Wallet) on
                      <span>Swappers Wall Chat. Find your peers on SwappersWall <a target='_blank' href='https://swapperswall.com/' >https://swapperswall.com/#</a></span>
                    </div>
                </div>
              </li>

              <li className="nav-item active">
                <Link className="nav-link" to="/CreateEvent">
                  Create Event
                  <span className="sr-only">(current)</span>
                </Link>
              </li>

              {/* <li className="nav-item dropdown">


                {
                  window.wallet ? (
                    <>
                      <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Profile 
                        <span className="sr-only">(current)</span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
                        <Link className="dropdown-item" to="/Myaccount">My account</Link>
                        <Link className="dropdown-item" to="/" onClick={() => {delete window.wallet}}>Log out</Link>
                      </div>
                    </>
                  ) : (
                    <a
                      className="nav-link"
                      href="https://eraswap.life/"
                      target="_blank"
                    >
                      {" "}
                      Connect To Wallet{" "}
                    </a>
                  )
                }


              </li> */}

            <li className="nav-item active">
              <Link className="nav-link" to="/Myaccount">My account</Link>
            </li>

            <li className="nav-item">
              
              {
                window.wallet ? (
                  <Link
                    className="nav-link btn btn-outline-warning conn-wallet-btn"
                    to="/"
                    onClick={() => {
                      delete window.wallet;
                    }}
                  >
                    {" "}
                    LOGOUT{" "}
                  </Link>
                ) : (
                  <a
                    className="nav-link btn btn-outline-warning conn-wallet-btn"
                    href="https://eraswap.life/create-new-wallet"
                    target="_blank"
                  >
                    {" "}
                    CONNECT TO WALLET{" "}
                  </a>
                )
              }
              
            </li>

            </ul>

          </div>
        </nav>

      </div>

    );

  }
}


export default Navbar;
