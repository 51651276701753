import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { Events } from './partials/Events';
import './SearchResult.css';

function SearchResult() {
    const categoryData = Events()

  const liveEvent = categoryData.filter((ele: any) => Date.now() < ((Number(ele[5])) * 1000)  )

    return (
        <>
          <Header />
          <div className="container my-3">
              <h4 className='mb-3' >{ liveEvent.length } Events available</h4>
              <div className="row">
                    {
                      categoryData.length > 0 ? categoryData.reverse().map((ele: any) => {
                        const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                        // TITLE ======
                        const title = descArray[0]?.replace(/['"]+/g, '')
                        // IMAGE =====
                        const image = descArray[3]?.replace(/['"]+/g, '')
                        // DATE ======
                        var fullDate = String(new Date((Number(ele[5])) * 1000))
                        var index = fullDate.lastIndexOf(':') +3

                        const date = fullDate.substring(0, index)
    
                        return ( Date.now()<((Number(ele[5])) * 1000) )
                        ? <div className="col-md-4 mb-3">
                              <div className='category-three-box'>
                                  <img className='box-Img-three' src={image ? image : './img/noPreview.png'} />
                                  
                                  <div className='rentCategory-text'>
                                      <div className="list-box-flex">
                                          <Link className='event-name text-clamp-1' to={'/EventDetail/'+ele[2]}>{title}</Link>
                                      </div>
                                      <div className="list-box-flex">
                                           <p className="location-txt mb-1"><strong>Location:</strong> {ele[4]}</p>
                                              <p className="location-txt">
                                                {
                                                  ( Date.now()<((Number(ele[5])) * 1000) )
                                                    ? <p><strong>Starting at:</strong> {date}</p>
                                                      : 'Ended'
                                                }
                                             </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        : null
                          
                      })
                            :
                      <>
                        <div className='no-available' >         
                           <h3>No event available</h3>
                        </div>
                      </>
                    }
            </div>
          </div>
        </>
    )
}
export { SearchResult };

