import React, { Component } from 'react';
// import { Col, Button, Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { StateList } from '../../components/StateList/StateList';
import './Homepage.css';

type Props = {};

type State = {
  
};


const routes = [
    {
        path: '/',
        exact: true,
        sidebar: () => <div className="ticket-wrap-para"><span className='transition-in' >List your event on BookingDapp by simply, clicking on Create an Event button, and mention all the required information of your event like – the Title, Description, Venue, Date, and Seat Map of your event..</span></div>,
    },
    {
        path: '/section2',
        sidebar: () => <div className="ticket-wrap-para"><span className='transition-in' >Once your event is successfully listed, add the number of tickets you are adding for sale, once your event tickets are listed, will connect you with your participants directly, in a P2P mode.</span></div>,
    },
    {
        path: '/section3',
        sidebar: () => <div className="ticket-wrap-para"><span className='transition-in' >Now, that all the process is completed, you can receive the value you deserve by selling your tickets directly, to the purchaser in the Peer-to-Peer mode, without paying huge cuts or multi-layer middlemen charges</span></div>,
    },
]

export class Homepage extends Component<Props, State>{

    constructor(props:Props) {
        super(props);
        this.state = {
            allEvents: [],
        };
    }

    getEvents = async () => {
        const filter = window.bookingdappInstance.filters.NewEvent(null, null, null, null, null, null);
        const logs = await window.bookingdappInstance.queryFilter(filter);
        const eventAll = logs.map(ele => ele.args);
        console.log("All :",eventAll)
        this.setState({...this.state , allEvents : eventAll})
        return eventAll;
    }

    async componentDidMount() {
        this.getEvents();
    }

    render() {
        //@ts-ignore
        var TxtType = function (el, toRotate, period) {
			//@ts-ignore
			this.toRotate = toRotate;
			//@ts-ignore
			this.el = el;
			//@ts-ignore
			this.loopNum = 0;
			//@ts-ignore
			this.period = parseInt(period, 10) || 2000;
			//@ts-ignore
			this.txt = '';
			//@ts-ignore
			this.tick();
			//@ts-ignore
            this.isDeleting = false;
        };

        TxtType.prototype.tick = function () {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];

            if (this.isDeleting) {
                this.txt = fullTxt.substring(0, this.txt.length - 1);
            } else {
                this.txt = fullTxt.substring(0, this.txt.length + 1);
            }

            this.el.innerHTML = '<span className="wrap">' + this.txt + '</span>';

            var that = this;
            var delta = 200 - Math.random() * 100;

            if (this.isDeleting) { delta /= 2; }

            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }

            setTimeout(function () {
                that.tick();
            }, delta);
        };

        window.onload = function () {
            var elements = document.getElementsByClassName('typewrite');
            for (var i = 0; i < elements.length; i++) {
                var toRotate = elements[i].getAttribute('data-type');
                var period = elements[i].getAttribute('data-period');
                if (toRotate) {
					//@ts-ignore
                    new TxtType(elements[i], JSON.parse(toRotate), period);
                }
            }
            // INJECT CSS
            var css = document.createElement("style");
            css.type = "text/css";
            css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
            document.body.appendChild(css);
        };

        //@ts-ignore
        const liveEvent = this.state.allEvents.filter((ele: any) => Date.now() < ((Number(ele[5])) * 1000))

        return (
            <div>
                <div className='booking-hero-bgd'>
                    <div className='wrapper-container-home'>
                        <Navbar />
                        <div className="hero-container">
                            <div>ALL THE FUN STARTS HERE</div>
                            <div className="discover-txt">Discover</div>
                            <div className="music-txt"><span className="music-color"><span className="typewrite" data-period="2000" data-type='[ "Concerts", "Sport Events", "Parties", "Conferences" ,"Social Events" ]'>
                                <span className="wrap"></span>
                            </span></span> around You.</div>
                        </div>

                        <form action='/searchResult' >
                            <div className="hero-category-container">
                                <div className="catg-main">
                                    <div className="part-select form-group">
                                       <h6>WHAT</h6>
                                       <select name='what' className="form-select" aria-label="Default select example">
                                          <option selected>Event Category </option>
                                          <option value="#business">Business/Promotion/Social Events</option>
                                          <option value="#concert">Concerts/Show</option>
                                          <option value="#fest">Fest</option>
                                          <option value="#seminars">Conferences/Seminars</option>
                                          <option value="#party">Party</option>
                                          <option value="#trip">Trip</option>
                                          <option value="#speech">Speech</option>
                                          <option value="#other">Other</option>
                                       </select>
                                    </div>

                                    
                                    
                                    <div className="part-select form-group">
                                       <h6>WHERE</h6>
                                        <select name='where' className="form-select" aria-label="Default select example">
                                           <StateList/>  
                                       </select>
                                    </div>

                                    <div className="part-select form-group">
                                        <h6>WHEN</h6>
                                        <input name='when' type="datetime-local" placeholder='Select date' className="form-control" />
                                    </div>
                                    
                                    <div className="part-select">
                                        <button type='submit' className="search-btn mt-1 mr-0">Search</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        
                    </div>
                </div>

                <div className="bookindapp-Container">
                    <div className='section' >
                        <div className='category-container'>
                            <p className="subtitle">Discover the fun!</p>
                            <div className="upcoming-display">
                                <h3 className="font-weight-bold">Upcoming Events</h3>
                                <Link to='/allevent' ><button className="search-btn" >View All</button></Link>
                            </div>

                            <div className='section-two-category'>
                                <div className='row'>
                               
                                     {
                                        //@ts-ignore
                                        liveEvent.splice(0,9).reverse().map((ele: any) => {
                                            const descArray = ele[3]?.replace(/\[|\]/g, "").split(',')
                                            // TITLE ======
                                            const title = descArray[0]?.replace(/['"]+/g, '')
                                            // IMAGE =====
                                            const image = descArray[3]?.replace(/['"]+/g, '')
                                            // DATE ======
                                            var fullDate = String(new Date((Number(ele[5])) * 1000))
                                            var index = fullDate.lastIndexOf(':') +3

                                            const date =  fullDate.substring(0, index)

                                            return ( Date.now() < ((Number(ele[5])) * 1000) )
                                                        ? <div className="col-md-4 mb-4">
                                                                <div className='category-three-box'>
                                                                    <img className='box-Img-three' src={image ? image : './img/noPreview.png'} />
                                                                    
                                                                    <div className='rentCategory-text'>
                                                                        <div className="list-box-flex">
                                                                            <Link className='event-name text-clamp-1' to={'/EventDetail/'+ele[2]}>{title}</Link>
                                                                        </div>
                                                                        <div className="list-box-flex">
                                                                            <p className="location-txt mb-1"><strong>Location:</strong> {ele[4]}</p>
                                                                            <p className="location-txt">
                                                                                {
                                                                                    ( Date.now()<((Number(ele[5])) * 1000) )
                                                                                    ? <p><strong>Starting at:</strong> {date}</p>
                                                                                    : 'Ended'
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null
                                        
                                                    
                                        })
                                     }

                                </div>
                            </div>          
                        </div>
                    </div>


                    <div className='section' >
                        <div className="container my-3">
                            <div className='entertain-category pb-0'>
                                <p className="subtitle-en">ALL THE FUN STARTS HERE !</p>
                                <h3 className="font-weight-bold-en mb-3">Entertainment Guides</h3>
                                <div className="row">
                    
                                <div className="col-md-4">
                                        <div className="grid">
                                            <figure className="effect-lily">
                                                <img className='box-Img-three' src="/img/event-4.jpg" />
                                                <figcaption>
                                                    <Link to='/EventPage#business' >
                                                      <div>
                                                        <h3>Social Events</h3>
                                                      </div>
                                                      <span className='more' >View more</span>
                                                    </Link>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                        <div className="grid">
                                            <figure className="effect-lily">
                                                <img className='box-Img-three' src="/img/event-1.jpg" />
                                                <figcaption>
                                                    <Link to='/EventPage#concert' >
                                                      <div>
                                                        <h3>Concerts</h3>
                                                      </div>
                                                      <span className='more' >View more</span>
                                                    </Link>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="grid">
                                            <figure className="effect-lily">
                                                <img className='box-Img-three' src="/img/event-10.jpg" />
                                                <figcaption>
                                                    <Link to='/EventPage#seminars' >
                                                      <div>
                                                        <h3>Seminars</h3>
                                                      </div>
                                                      <span className='more' >View more</span>
                                                    </Link>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="row justify-content-center">

                                    <div className="col-md-4">
                                            <div className="grid">
                                                <figure className="effect-lily">
                                                    <img className='box-Img-three' src="/img/party.jpg" />
                                                    <figcaption>
                                                       <Link to='/EventPage#party' >
                                                        <div>
                                                            <h3>Party</h3>
                                                        </div>
                                                        <span className='more' >View more</span>
                                                       </Link>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <div className="grid">
                                                <figure className="effect-lily">
                                                    <img className='box-Img-three' src="/img/event-8.jpg" />
                                                    <figcaption>
                                                       <Link to='/EventPage#trip' >
                                                        <div>
                                                            <h3>Trip</h3>
                                                        </div>
                                                        <span className='more' >View more</span>
                                                       </Link>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                        <div className="col-md-4">

                                            <div className="grid">
                                                <figure className="effect-lily">
                                                    <img className='box-Img-three' src="/img/event-11.jpg" />
                                                    <figcaption>
                                                       <Link to='/EventPage#speech' >
                                                        <div>
                                                            <h3>Speech</h3>
                                                        </div>
                                                        <span className='more' >View more</span>
                                                       </Link>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="grid">
                                                <figure className="effect-lily">
                                                    <img className='box-Img-three' src="/img/fest.jpg" />
                                                    <figcaption>
                                                       <Link to='/EventPage#fest' >
                                                        <div>
                                                            <h3>Fest</h3>
                                                        </div>
                                                        <span className='more' >View more</span>
                                                       </Link>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="grid">
                                                <figure className="effect-lily">
                                                    <img className='box-Img-three' src="/img/eventEight.jpg" />
                                                    <figcaption>
                                                       <Link to='/EventPage#other' >
                                                        <div>
                                                            <h3>Other</h3>
                                                        </div>
                                                        <span className='more' >View more</span>
                                                       </Link>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="book-box-img section">
                        <img className='bookingOffer-img' src="/img/bookingOffer.jpg" />
                    </div>
                    
                    
                    <div className="container section pt-0">
                        <div className="margin-left-ticket">
                            <div className="row">
                                <Router>
                                <div className="col-md-6">
                                        <p className="subtitle">How to</p>
                                        <h3 className="font-weight-bold">Sell Your Tickets</h3>

                                        {routes.map((route) => (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.sidebar}
                                            />
                                        ))}

                                        <div className="sub-tick-txt">
                                            <Link to="/"><h4 className="pb-3-txt"><span className="text-red">01</span>Create an Event</h4></Link>
                                            <Link to="/section2"> <h4 className="pb-3-txt"><span className="text-red">02</span>Add Tickets</h4></Link>
                                            <Link to="/section3"> <h4 className="pb-3-txt"><span className="text-red">03</span>Earn Money</h4></Link>
                                        </div>
                                    </div>
                                </Router>
                                <div className="col-md-4">
                                    <img className='sideticket-img' src="/img/eventNine.jpg" />
                                </div>
                            </div>
                            <div className="started-box mt-2">
                                <Link to='/CreateEvent' ><button className="Started-btn-btn">Get Started</button></Link>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <div className="testimonial-container">
                         
                            <h3 className="font-weight-bold">Hear From Our Organizers</h3>
                            <div className="container pt-0">
                                <div className="row">
                                <div className="col-md-4">
                                        <div className="org-head-txt">BookingDapp Features</div>
                                        <p>Some unique features that make Booking Dapp different</p>
                                        <div className="">
                                            {/* <img className='user-img-pf' src={Images.path.imgProfile} /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="org-head-txt">Decentralized</div>
                                        <p>BookingDapp is decentralized, and with a decentralized approach BookingDapp, keeps middlemen and all the parts of centralized platforms which make the process difficult and slow.</p>

                                        <div className="">
                                            {/* <img className='user-img-pf' src={Images.path.imgProfile} /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="org-head-txt">P2P Connectivity</div>
                                        <p>BookingDapp gives Buyer and Seller, a complete self-sustainable platform to transact, so user here can connect and transact with each other, in a Peer-to-Peer mode, to make the complete process efficient and fast.</p>
                                        <div className="">
                                            {/* <img className='user-img-pf' src={Images.path.imgProfile} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="video" className="main-section center-align">
                    <div className="container mt60 mb60 m-auto">
                        <h3 className="reflection-text reflection-text-white ">Watch Video</h3>
                        <p className="p-t-1 "> Watch this video to know more about <b>BookingDapp</b> </p>

                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ywfJ9h1lnCM?start=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                    </div>
                </section>

            </div>
        );

    }
}

export default Homepage;
