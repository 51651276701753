import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
// import Modal from 'react-bootstrap/Modal';
import {
    Link, useParams
} from 'react-router-dom';
// import { Col, Button, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import { EventManagerFactory } from '../../ethereum/typechain/EventManagerFactory';
import './EventDetail.css';

export default function EventDetail() {

    const [state, setstate] = useState({owner: '', title: 'Loading...', desc:'Loading...', location: 'Loading...', start: 0, startTime:[''], seatsPerType: [0], pricePerType: [0], minPrice: 0, maxPrice: 0, count: 0, income: 0, status: true, incentive: 0,image:'/img/loader.gif',country:'',category:'',name:'loading...',phone:'loading...',email:'loading...',website:''});
    const [modalstate, setmodalstate] = useState({showReviewModal: false}); 
    const [first, setFirst] = useState <number[]> ([]);
    const [second, setSecond] = useState <number[]> ([]);
    const [user, setUser] = useState("None");
    const [bookedSeats, setBookedSeats] = useState<number[]> ([]);
    const [bookedUsers, setBookedUsers] = useState<any[]> ([]);

    const handleReviewClose = () => {
        setmodalstate({
            showReviewModal: false,
        })
    }

    // @ts-ignore
    const { address } = useParams();
    
    const eventInstance = EventManagerFactory.connect(
        address,
        window.wallet ?? window.provider
    );

    const fetchData = async () => {
        if(window.wallet !== undefined)
        {
            var userWallet = await window.wallet.getAddress();
            setUser(userWallet);
        }
        // console.log(user);
        const owner = await eventInstance.eventOwner();
        const title = await eventInstance.eventName();
        const desc = await eventInstance.eventDesc();
        const location = await eventInstance.eventLocation();
        const startTime1 = await (await eventInstance.eventStartTime()).toNumber();
        const start = startTime1*1000;
        const startTime2 = ((new Date(startTime1*1000)).toString()).split("GMT+0530 (India Standard Time)");
        const startTime = startTime2[0].split(' ');
        const seatsPer = await eventInstance.getSeats();
        const seatsPerType = seatsPer.map(s => s.toNumber());
        const pricePer = await eventInstance.getPrices();
        const pricePer1 = pricePer.map(s => ethers.utils.formatEther(s));
        const pricePerType = pricePer1.map(s => +s);
        const seats = await (await eventInstance.totalSeats()).toNumber();
        const wallet = await eventInstance.wallet();
        const wallet1 = ethers.utils.formatEther(wallet);
        const income = Number(wallet1);
        const incen = await eventInstance.incentive();
        const incentive = Number(incen);
        const status = await eventInstance.eventStatus();
        var count = 0;

        const seatCnt = await eventInstance.getSeatsCount();
        var seatCnt1 = seatCnt.map(s => s.toNumber());

        var seatCnt2: number[] = [];
        seatCnt2.push(1);
        
        for(var i=1; i<seatCnt1.length; i++)
        {
            seatCnt1[i] += seatCnt1[i-1];
        }
        for(var i=0; i<seatCnt1.length-1; i++)
        {
            seatCnt2.push(seatCnt1[i] + 1);
        }

        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }

        var booked: number[] = [];
        var booked1: any[] = [];

        for(var i=1; i<=seats; i++)
        {
            var addr = await eventInstance.seatOwner(i);
            // console.log(addr);
            if(addr === window.wallet.address)
                count++;

            if(addr !== "0x0000000000000000000000000000000000000000")
            {
                booked.push(i);
                booked1.push(addr);
            }
        }

        const minPrice = Math.min(...pricePerType);
        const maxPrice = Math.max(...pricePerType);

        const descArray = title?.replace(/\[|\]/g, "").split(',')
        // TITLE ======
        const titl = descArray[0]?.replace(/['"]+/g, '')
        // IMAGE =====
        const image = descArray[3]?.replace(/['"]+/g, '')
        // COUNTRY ====
        const country = descArray[2]?.replace(/['"]+/g, '')
        // CATEGORY ====
        const category = descArray[1]?.replace(/['"]+/g, '').split('#')
        // CATEGORY ====
        const name = descArray[4]?.replace(/['"]+/g, '')
        // CATEGORY ====
        const phone = descArray[5]?.replace(/['"]+/g, '')
        // CATEGORY ====
        const email = descArray[6]?.replace(/['"]+/g, '')
        // CATEGORY ====
        const website = descArray[7]?.replace(/['"]+/g, '')

        //@ts-ignore
        setstate({owner, title:titl, desc, location, start, startTime, seatsPerType, pricePerType, minPrice, maxPrice, count, income, status, incentive,image:image,country:country,category:category,name:name,phone:phone,email:email,website:website});

        setBookedSeats(booked);
        setBookedUsers(booked1);

        setFirst(seatCnt2);
        setSecond(seatCnt1);
    }

    useEffect(()=>{(async () =>
        {
          await fetchData();
        }
      )()
    },[])


    const cancelEvent = async () => {
        if(window.wallet===undefined)
        {
            alert("Wallet not loaded");
            return;
        }

        try {
            // alert(state.income);
            const tx = await eventInstance.connect(window.wallet).cancelEvent({value: ethers.utils.parseEther(String(state.income))});
            tx.wait();
            console.log(tx);

            alert("Event is cancelled and ticket amounts refunded");
        } catch (error) {
            alert(error.message);
            console.log(error.message);
        }
    }

    console.log(state)

    return (
        <div>
            <Header />
            <br/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className='category-event-box'>
                            <img className='event-Img-three' src={state.image ? state.image : '/img/noPreview.png'} />

                            <div className='listing-text-ev'>
                                <div className="list-box-flex">
                                    <p className="location-txt mt-2" style={{fontSize:'21px',color:"#002E91"}} >{state.title}</p>
                                </div>                                
                                <div className="list-box-flex d-flex justify-content-between pb-2">
                                    <p className="location-txt">{state.country}</p>
                                    <p className="location-txt">ES {state.minPrice} - ES {state.maxPrice}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="event-main-box">
                    <h5 className="ev-detail-name">{state.title}</h5>
                    
                    <ReactMarkdown source={state.desc} escapeHtml={false} />
                    {/*<p className="event-desc">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure </p>*/}
                    <div className="address-head mb-2">
                        <p className='event-name mb-0' >CATEGORY</p>
                        <p className="event-desc1">{state.category}</p>
                    </div>

                    <div className="address-head mb-2">
                        <p className='event-name mb-0' >ADDRESS</p>
                        <p className="event-desc1">{state.location}</p>
                    </div>

                    <div className="address-head">    
                       <p className='event-name mb-2' >Organizer info</p>
                       <ul className='organizer-info pl-2' >
                          {state.name && <li><strong>Name : </strong> {state.name} </li>}
                          {state.phone && <li><strong>Contact number : </strong> {state.phone} </li>}
                          {state.email && <li><strong>Email Address : </strong> {state.email} </li>}
                          {state.website && <li><strong>Website URL : </strong> <a target="_blank" href={state.website} >{state.website}</a> </li>}
                       </ul>
                    </div>
                    
                    {
                        (state.status === true)
                        ?    <>
                                <label>{state.incentive} % incentive is announced!!</label>
                                <br/>
                                <label>TICKETS</label>
                                <p className="concert-name">For {state.title}</p>
                                <div className="event-time-box">
                                    <div>
                                        <p>
                                            {state.startTime[1]} {state.startTime[2]} {state.startTime[3]}, {state.startTime[0]}
                                        </p>
                                        <p>Starts at {state.startTime[4]}</p>
                                        <p>
                                            {
                                                (Date.now() < state.start)
                                                ? "Event Upcoming!!"
                                                : "Event Ended" 
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        {
                                            state.pricePerType.map((ele, i) => (
                                                <p><span className="hex-txt">&#x2022;</span> Type {i+1} ticket (Seat numbers: {first[i]} - {second[i]}) : ES {ele} ({state.seatsPerType[i]} seats left) </p>
                                            ))
                                        }
                                    </div>
                                    <div>
                                        <p>
                                            <Link to={'/PreviewTicket/'+address}><button className="Started-btn-btn">Book Tickets</button></Link>
                                        </p>
                                    </div>
                                </div>
                            </>
                        :   <p className="concert-name">Event has been cancelled by the organizer!!</p>
                    }   
                </div>

                <div className="event-main-box">
                    {
                        //@ts-ignore
                        (state.owner === window.wallet.address  && state.status === true)
                        ? <button className="Started-btn-btn" onClick={cancelEvent}>Cancel Event</button>
                        : null
                    }
                </div>
                <br/><br/>
                             
            </div>
            {/* Bootstrap Modal two */}
            <div className="modal"  role="dialog" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img className='rate-imgs' src=""/>4.8<h5 className='review-heading-style'>50(reviews)</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div  className="row show-grid">
                                    <div className="col-xs-12 col-sm-6 col-md-6">
                                        <div className='user-review'>
                                            <div className='user-detail-container'>
                                                <img className='user-img-style' src="/img/imgProfile.jpg" />
                                                <div>
                                                    <h5>Jessica Does</h5>
                                                    <p className='review-date-txt'>March 2020</p>
                                                </div>
                                            </div>
                                            <p>Thank you ,We loved our stay,stunning place </p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6">
                                        <div className='user-review'>
                                            <div className='user-detail-container'>
                                                <img className='user-img-style' src="/img/imgProfile.jpg" />
                                                    <div>
                                                    <h5>Jessica Does</h5>
                                                    <p className='review-date-txt'>March 2020</p>
                                                </div>
                                            </div>
                                                <p>Thank you ,We loved our stay,stunning places </p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6">
                                        <div className='user-review'>
                                            <div className='user-detail-container'>
                                            <img className='user-img-style' src="/img/imgProfile.jpg" />
                                                <div>
                                                    <h5>Jessica Does</h5>
                                                    <p className='review-date-txt'>March 2020</p>
                                                </div>
                                            </div>
                                            <p>Thank you ,We loved our stay,stunning place </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-primary">Save changes</button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> */}
                    </div>
                </div>
            </div>
        </div>
    );

}